<template>
  <div class="bbox">
    <div class="jiejue-box"><span class="jj-text">公司简介</span></div>
    <div class="jianjie">
      山西中天云网科技有限公司(中天云网)成立于2022年6月、注册资金1000万，以城市互联网为核心，专注于新型AI智慧城市建设运营以及元宇宙、电子竞技、视频审核、再生资源等多元化并行的综合性企业。为用户提供从解决方案项目实施到运营维护的全生命周期的技术服务。公司秉承“诚信、协作、高效、创新的企业宗旨，为城市建设贡献责任担当。
    </div>
    <div><img class="ditu" src="@/assets/images/dt.png" alt="" /></div>
    <div class="tit">- 中天云网公司分布和建设覆盖省份图 -</div>
    <div class="box">
      <!-- <div class="min-box">
        <div>
          <img class="dm-img" src="@/assets/images/dm0.png" alt="" />
        </div>
        <div class="dm-text">
          <div>
            北京中关村软件园 <span style="font-size:.24rem">（北京总部）</span>
          </div>
          <div class="zhnl">战略发展中心</div>
        </div>
      </div> -->
      <!-- <div class="min-box">
        <div>
          <img class="dm-img" src="@/assets/images/dm2.png" alt="" />
        </div>
        <div class="dm-text">
          <div>
            山西清控创新基地
            <span style="font-size:.24rem">（山西运营中心）</span>
          </div>
          <div class="zhnl">运营中心</div>
        </div> -->
      <!-- </div>
      <div class="min-box">
        <div>
          <img class="dm-img" src="@/assets/images/dm3.png" alt="" />
        </div>
        <div class="dm-text">
          <div>
            成都天府软件园
            <span style="font-size:.24rem">（成都研发中心）</span>
          </div>
          <div class="zhnl">数据中台、智慧应用研发中心</div>
        </div>
      </div>
      <div class="min-box">
        <div>
          <img class="dm-img" src="@/assets/images/dm3.png" alt="" />
        </div>
        <div class="dm-text">
          <div>
            深圳南山科技园
            <span style="font-size:.24rem">（深圳物联网中心）</span>
          </div>
          <div class="zhnl">物联网平台产品</div>
        </div>
      </div> -->
      <!-- <div class="min-box">
        <div>
          <img class="dm-img" src="@/assets/images/dm3.png" alt="" />
        </div>
        <div class="dm-text">
          <div>
            邯郸新经济产业园
            <span style="font-size:.24rem">（河北解决方案中心）</span>
          </div>
          <div class="zhnl">智慧城市顶层设计</div>
        </div>
      </div> -->
    </div>
    <div class="jianjie">
      中天云网依托丰富的政府、企业和民生信息化领域的实践经验、成熟的开发实施与运维方法论、卓越的项目团队、
      严格的项目与质量管理体系以及覆盖全国的销售、技术与服务支持网络，以客户为中心，承担智慧城市相关的多种类型的集成、实施与运维项目，
      快速规范并高质量地打造各类智慧城市信息化系统，实现智慧城市的建设目标。凭借全面、务实的业务能力、开放式的创新能力及资源整合能力，
      中天云网提供从基础设施及平台建设、软件服务到商业运营全方面的“智慧城市”解决方案，长期致力于“智慧城市”的建设和服务。
      采用“咨询规划—实施集成—运营维护”一体化的服务模式，提供智慧城市建设的“端到端”解决方案，
      通过战略规划、部署实施和后期运营的三步实施路线，进行云基础设施、基础平台和智慧应用多个层次的系统建设。
    </div>
    <div><img class="lingyu" src="@/assets/images/ly.png" alt="" /></div>
    <div class="tit">- 中天云网服务领域图 -</div>
    <div class="jianjie">
      中天云网凭借对市场敏锐的触觉，对行业独特的理解，对技术严格的要求，对服务标准化的管理，对运营灵活的创新科技，面对变化莫测的市场环境，
      日新月异的技术更迭，中天云网秉承“创新引领未来”的理念，结合时代，结合市场，结合用户的需求，在原有的基础上不断升级，不断创新，
      不断自我突破，形成了今天科技化、数据化、智能化全新的运营模式，逐步成长为一家区域领先的科技文化公司，
      在互联网、物联网、人工智能、大数据及游戏等技术领域处于领先地位，形成了一条全面满足客户多方需求的完整产品链条。
      精英汇聚，逐鹿中原，在多元化、国际化的发展进程中，中天云网全方位打造员工的综合素质，培育了一支快速反应的专业团队，
      汇聚了一批优秀的科技精英，其研发应用水平始终与全球前沿科技保持同步，为国内政府、企业信息化普及和数字化运营提供了强有力了的服务支持和技术保障。
      未来，中天云网依然会不忘初心，以高瞻远瞩的眼光和永不止步的创新精神，与广大客户和战略伙伴强强联手，在可持续发展的道路上不断探索，砥砺前行！
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.jiejue-box {
  height: 4.2rem;
  margin: auto;
  background: url(../../../../assets/images/gs0.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  position: relative;
  margin: auto;
}
.jiejue-box::after {
  content: '';
  display: block;
  width: 100%;
  height: 4.2rem;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.28);
}
.jj-text {
  font-size: 0.6rem;
  font-weight: bold;
  color: #ffffff;
  line-height: 0.35rem;
  line-height: 4.2rem;
  position: relative;
  z-index: 10;
}
.jianjie {
  font-size: 0.28rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.5rem;
  padding: 0.3rem;
}
.ditu {
  width: auto;
  height: 5.45rem;
  display: block;
  margin: auto;
}
.tit {
  text-align: center;
  font-size: 0.24rem;
  font-weight: bold;
  color: #333333;
  line-height: 0.18rem;
  padding: 0.3rem 0;
}
.min-box {
  display: flex;
  align-items: center;
  border: 1px solid #666666;
  margin: 0.3rem;
}
.dm-img {
  width: 2.1rem;
  height: 1.9rem;
}
.dm-text {
  font-size: 0.28rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.39rem;
  padding-left: 0.3rem;
}
.zhnl {
  font-size: 0.26rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.35rem;
}
.lingyu {
  width: 6.38rem;
  height: 6.47rem;
  display: block;
  margin: auto;
}
</style>
